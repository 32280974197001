<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Tipo Colorazione"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      > </ExportExcelTable>
      <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-2" style="width: 100%">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :show-filters="false"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Nome: {{ filter.value }}
          </template>

        </AdvancedFilter>
      </div>
      <div 
        :style="{
          'height': $vuetify.breakpoint.smAndDown ? '81%' : '86%', 
          'overflow-y': 'hidden'
        }" 
        ref="dataTableWrapper"
      >
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento Tipo Colorazione ..."
          :show-select="false"
          :headers="headers"
          :loading="loading"
          :items="filteredColorLines"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          @edit="handleEdit"
          @delete="handleDelete"
        ></TypeDataTable>
      </div>
    </template>
  </FullScreenDialog >
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import colorLinesService from '@/services/colorLines/colorLines.service.js'
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import dateUtils from "@/mixins/common/dateUtils"

export default {
  name: "ColorLinesRegistryList",
  components: {
    FullScreenDialog,
    AdvancedFilter,
    TypeDataTable,
    StandardMenu,
    ExportExcelTable
  },
  mixins: [dateUtils],
  data: function() {
    let props = this.$router.resolve({name: 'Registries'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      colorLines: [],
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 0,
      resizeListener: undefined,
      worksheetName: "Lista Tipo Colorazione",
      fileName: "",
      fieldsToExport: {},
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [],
      isLicenseBlocked: undefined
    }
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.filtersValue = this.filters
    this.fetchColorLines()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)
    
    this.fileName = "Tipi_Colorazione_" + this._buildDateToExport(new Date()) +
      ".xls";

    this.fieldsToExport = colorLinesService._fieldsToExport()
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchColorLines() {
      let filters = [...this.filtersValue]
      this.loadingItems = true
      colorLinesService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(!!this.totalPages && this.totalPages < this.page) {
          this.page = this.totalPages
        }

        this.colorLines = results.rows
        this.loadingItems = false
      })
    },
    async fetchExcel(){
      let results = await colorLinesService.list(this.page, this.rowPerPage, undefined)

      return results.rows
    },
    fetchHeaders() {
      this.loadingHeaders = true
      colorLinesService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleEdit(colorLine) {
      this.$router.push({
        name: 'ColorLinesRegistryEditForm',
        params: {
          id: colorLine.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'ColorLinesRegistryList'}).href,
          pathName: "ColorLinesRegistryList"
        }
      })
    },
    handleDelete(colorLine) {
      let confirmed = confirm('Sei sicuro di voler eliminare ' + colorLine.name + ' ?')

      if(confirmed) {
        this.loadingItems = true
        colorLinesService.delete(colorLine).then(() => {
          this.fetchColorLines()
          colorLinesService.setAlreadyLoaded(false)
          this.loadingItems = false
        })
      }
    },
    goToNew() {
      this.$router.push({
        name: 'ColorLinesRegistryNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'ColorLinesRegistryList'}).href
        }
      })
    },
    applyFilters() {
      this.fetchColorLines();
    },
  },
  computed: {
    filteredColorLines() {
      return this.colorLines
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
    page() {
      this.fetchColorLines()
    },
    rowPerPage() {
      this.fetchColorLines()
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
}
</script>